<template>
  <div class= "page-buttons-container mt-5">
    <b-button v-for="button in buttons" :key="button.title" 
      @click="button.clickCallback" 
      align="center" 
      class="page-buttons my-2"
        >{{button.title}}
      </b-button>
        <router-link to="/howto">
          <p
          style="font-size: 1.4rem;"
          class="text-default text-decoration" 
          :class="(isDarkTheme ? 'text-light': 'text-dark')"
          ><u>{{$t('view.home.howto')}}</u></p>
        </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  props: {
    buttons: Array,
  },
  computed: {
    ...mapState({
      isDarkTheme: state => state.settings.isDarkTheme,
    })
  }
}
</script>
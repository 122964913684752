<template>
	<div class="home-container">
		<Navbar :showHomeButton="false"></Navbar>
		<div class="home-logo-container">
			<img class="home-logo" src="@/assets/img/logo/main-logo.png" />
		</div>

		<PageButtons :buttons="buttons"></PageButtons>
	</div>
</template>


<script>
import Navbar from "@/components/Navbar.vue";
import PageButtons from "@/components/PageButtons.vue";

export default {
	title() {
		return this.$t("view.files.name");
	},
	computed: {
		buttons() {
			return [
				{
					// arrow functions here so that $router and locales can be used accessed
					clickCallback: () => {
            this.$router.push(this.$t("view.me.route"));
					},
					title: this.$t("view.files.myFilesButton"),
				},
				{
					clickCallback: () => {
						this.$router.push(this.$t("view.public.route"));
					},
					title: this.$t("view.files.publicFilesButton"),
				},
			];
		},
	},
	components: {
		Navbar,
		PageButtons,
	},
};
</script>
